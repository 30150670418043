import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"


import { Section, Container, Flex, Column, Content } from "@atoms/layout"
import { Heading, Paragraph } from "@atoms/typography"
import Layout from "@utils/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { media } from "@styles/breakpoints"

// PAPERCRAFT
import PapercraftAutomation from "@molecules/papercraft/automation"
import PapercraftBackOffice from "@molecules/papercraft/backOffice"
import PapercraftBilling from "@molecules/papercraft/billing"
import PapercraftCheckouts from "@molecules/papercraft/checkouts"
import PapercraftRecapture from "@molecules/papercraft/recapture"

// BLOCKS
import BlockAcceptPayments from "@molecules/featureBlocks/blockAcceptPayments"
import BlockAutoEmails from "@molecules/featureBlocks/blockAutoEmails"
import BlockBillingCharges from "@molecules/featureBlocks/blockBillingCharges"
import BlockContentWImage from "@molecules/featureBlocks/blockContentWImage"
import BlockDeveloperTools from "@molecules/featureBlocks/blockDeveloperTools"
import BlockFeaturesFourColumns from "@molecules/featureBlocks/blockFeaturesFourColumns"
import BlockFeaturesSimple from "@molecules/featureBlocks/blockFeaturesSimple"
import BlockFeaturesThreeColumns from "@molecules/featureBlocks/blockFeaturesThreeColumns"
import BlockMatchYourBrand from "@molecules/featureBlocks/blockMatchYourBrand"
import BlockIntegrations from "@molecules/featureBlocks/blockIntegrations"
import BlockOpenForms from "@molecules/featureBlocks/blockOpenForms"
import BlockSecurity from "@molecules/featureBlocks/blockSecurity"


const FeatureTemplate = ({ data }) => {
  const feature = data.datoCmsFeature

  return (
    <Layout>
      <HelmetDatoCms seo={feature.seoMetaTags}/>
      <div data-datocms-noindex>
        <SectionHeader aqua>
          <Container>
            <Flex twoCol alignCenter>
              <ColumnContent half>
               <Content paragraph>
                  <Heading html="h6" level="sh" strong gray>
                    {feature.title}
                  </Heading>
                  <Heading html="h1" level="h1">
                    {feature.heading}
                  </Heading>
                  <Paragraph level="lg">
                    {feature.intro}
                  </Paragraph>
               </Content>
              </ColumnContent>
              <Column>
                {
                  feature.slug === "automation" ? <PapercraftAutomation /> :
                    feature.slug === "back-office" ? <PapercraftBackOffice /> :
                      feature.slug === "billing" ? <PapercraftBilling /> :
                        feature.slug === "checkouts" ? <PapercraftCheckouts /> :
                          feature.slug === "recapture" ? <PapercraftRecapture /> :
                            <h1>No Papercraft</h1>
                }
              </Column>
            </Flex>
          </Container>
        </SectionHeader>
          {feature.content.map((block, index) => (
            <React.Fragment key={index}>
              {
                block.model.apiKey === "block_accept_payment" ? <BlockAcceptPayments block={block} /> :
                  block.model.apiKey === "block_auto_email" ? <BlockAutoEmails block={block}/> :
                    block.model.apiKey === "block_billing_charge" ? <BlockBillingCharges block={block}/> :
                      block.model.apiKey === "block_content_w_image" ? <BlockContentWImage block={block}/> :
                        block.model.apiKey === "block_developer_tool" ? <BlockDeveloperTools block={block}/> :
                          block.model.apiKey === "block_features_four_column" ? <BlockFeaturesFourColumns block={block}/> :
                            block.model.apiKey === "block_features_simple" ? <BlockFeaturesSimple block={block}/> : 
                              block.model.apiKey === "block_features_three_column" ? <BlockFeaturesThreeColumns block={block}/> :
                                block.model.apiKey === "block_match_your_brand" ? <BlockMatchYourBrand block={block}/> : 
                                  block.model.apiKey === "block_integration" ? <BlockIntegrations block={block}/> :
                                    block.model.apiKey === "block_open_form" ? <BlockOpenForms block={block}/> :
                                      block.model.apiKey === "block_security" ? <BlockSecurity block={block} /> : 
                                        <h1>No Corrosponding Block</h1>
              }
            </React.Fragment>
          ))}

      </div>
    </Layout>
  )
}

export default FeatureTemplate

export const fetchFeatureTemplate = graphql`
  query fetchFeatureTemplate($slug: String!) {
    datoCmsFeature(slug: { eq: $slug }) {
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      navigationTitle
      title
      heading
      intro
      content {
        ...BlockAcceptPayment
        ...BlockAutoEmail
        ...BlockBillingCharge
        ...BlockContentWImage
        ...BlockDeveloperTools
        ...BlockFeaturesFourColumn
        ...BlockFeaturesSimple
        ...BlockFeaturesThreeColumn
        ...BlockIntegrations
        ...BlockMatchYourBrand
        ...BlockOpenForm
        ...BlockSecurity
      }
    }
  }
`

const ColumnContent = styled(Column)`
  margin-bottom: 0;
`

const SectionHeader = styled(Section)`
  padding-top: var(--spacing--xl);

  ${media.sm`
    padding-top: var(--spacingNavigation);
  `}
`
