import React, { useRef, useState, useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { Section, Container, Flex, Column } from "@atoms/layout"
import { Heading, Paragraph } from "@atoms/typography"
import { media } from "@styles/breakpoints"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const AutoEmails = ({ block }) => {
  const [imagesSlider, setImagesSlider] = useState()
  const [controlsSlider, setControlsSlider] = useState()
  const [stop, setStop] = useState(false)

  const images = useRef(null)
  const controls = useRef(null)

  useEffect(() => {
    setImagesSlider(images.current)
    setControlsSlider(controls.current)
  }, [])

  const imagesSettings = {
    arrows: false,
    autoplay: true,
    dots: false,
    fade: true,
    lazyLoad: "progressive",
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    pauseOnFocus: true,
    autoplaySpeed: 8000,
  }

  const controlsSettings = {
    arrows: false,
    autoplay: true,
    dots: false,
    focusOnSelect: true,
    slidesToShow: 3,
    pauseOnHover: true,
    pauseOnFocus: true,
    autoplaySpeed: 8000,
  }

  const pause = () => {
    imagesSlider.slickPause()
    controlsSlider.slickPause()
  }

  const play = () => {
    if (!stop) {
      imagesSlider.slickPlay()
      controlsSlider.slickPlay()
    }
  }

  return (
    <Section lg lgTop>
      <Container>
        <Flex alignCenter>
          <Column forty>
            <Slider {...imagesSettings} ref={images} asNavFor={controlsSlider}>
              <GatsbyImage
                image={block.oneImage.gatsbyImageData}
                alt={block.oneImage.alt}
                title={block.oneImage.title}
              />
              <GatsbyImage
                image={block.twoImage.gatsbyImageData}
                alt={block.twoImage.alt}
                title={block.twoImage.title}
              />
              <GatsbyImage
                image={block.threeImage.gatsbyImageData}
                alt={block.threeImage.alt}
                title={block.threeImage.title}
              />
            </Slider>
          </Column>
          <Column sixty>
            <Container containerSmall>
              <Heading html="h2" level="h2">
                {block.title}
              </Heading>
              <Paragraph
                level="md"
                dangerouslySetInnerHTML={{ __html: block.content }}
              />
            </Container>
            <Nav ref={controls} asNavFor={imagesSlider} {...controlsSettings}>
              <div>
                <Card
                  onMouseEnter={pause}
                  onMouseLeave={play}
                  onClick={() => setStop(true)}
                >
                  <Radio />
                  <Heading html="h3" level="h4">
                    {block.oneTitle}
                  </Heading>
                  <Paragraph
                    level="sm"
                    dangerouslySetInnerHTML={{ __html: block.oneContent }}
                  />
                </Card>
              </div>
              <div>
                <Card
                  onMouseEnter={pause}
                  onMouseLeave={play}
                  onClick={() => setStop(true)}
                >
                  <Radio />
                  <Heading html="h3" level="h4">
                    {block.twoTitle}
                  </Heading>
                  <Paragraph
                    level="sm"
                    dangerouslySetInnerHTML={{ __html: block.twoContent }}
                  />
                </Card>
              </div>
              <div>
                <Card
                  onMouseEnter={pause}
                  onMouseLeave={play}
                  onClick={() => setStop(true)}
                >
                  <Radio />
                  <Heading html="h3" level="h4">
                    {block.threeTitle}
                  </Heading>
                  <Paragraph
                    level="sm"
                    dangerouslySetInnerHTML={{ __html: block.threeContent }}
                  />
                </Card>
              </div>
            </Nav>
          </Column>
        </Flex>
      </Container>
    </Section>
  )
}

export default AutoEmails

export const blockAutoEmails = graphql`
  fragment BlockAutoEmail on DatoCmsBlockAutoEmail {
    id
    title
    content
    model {
      apiKey
    }
    oneImage {
      gatsbyImageData(
        width: 800
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
      alt
    }
    oneTitle
    oneContent
    twoImage {
      gatsbyImageData(
        width: 800
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
      alt
    }
    twoTitle
    twoContent
    threeImage {
      gatsbyImageData(
        width: 800
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
      alt
    }
    threeTitle
    threeContent
  }
`

const Card = styled.div`
  border: var(--borderPrimary);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 10rem;
  padding: 1rem 1rem 0.25rem;
  transition: var(--transitionShort);

  ${media.sm`
    height: 14rem;
  `}

  ${media.md`
    height: 15rem;
  `}

  &:hover {
    cursor: pointer;
    border-color: var(--gray--40);
  }
`

const Radio = styled.div`
  background-color: var(--gray--20);
  border-radius: 50%;
  height: 24px;
  margin-bottom: 0.75rem;
  position: relative;
  transition: var(--transitionShort);
  width: 24px;

  &:after {
    background-color: var(--gray--40);
    border-radius: 50%;
    content: "";
    height: 8px;
    left: 8px;
    position: absolute;
    top: 8px;
    transition: var(--transitionShort);
    width: 8px;
  }
`

const Nav = styled(Slider)`
  margin-top: var(--spacing--sm);

  .slick-track {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }

  .slick-slide {
    flex: 0 0 100%;
    outline: none !important;
    padding: 0 0.5rem 1rem;
    width: 50%;

    ${media.sm`
      flex: 0 0 33.3%;
    `}

    * {
      outline: none !important;
    }

    &.slick-current {
      ${Card} {
        border-color: var(--colorPrimary);
      }

      ${Radio} {
        background-color: var(--aqua--20);

        &:after {
          background-color: var(--colorPrimary);
        }
      }
    }
  }
`
