import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { Heading, Paragraph } from "@atoms/typography"
import { Section, Container, Flex, Column } from "@atoms/layout"

const Integrations = ({ block }) => {
  return (
    <Section lg lgTop light full>
      <Container section>
        <Flex>
          <Column half>
            <Heading html="h2" level="h2">
              {block.heading}
            </Heading>
            <Paragraph
              level="md"
              dangerouslySetInnerHTML={{ __html: block.content }}
            />
          </Column>
        </Flex>
      </Container>
      <Container containerFull sm smTop>
        <GatsbyImage
          image={block.image.gatsbyImageData}
          alt={block.image.alt}
          title={block.image.title}
        />
      </Container>
      <Container section smTop>
        <Flex>
          <Column third>
            <Heading html="h4" level="h4">
              {block.sectionHeadingOne}
            </Heading>
            <Paragraph level="md">{block.sectionContentOne}</Paragraph>
          </Column>
          <Column third>
            <Heading html="h4" level="h4">
              {block.sectionHeadingTwo}
            </Heading>
            <Paragraph level="md">{block.sectionContentTwo}</Paragraph>
          </Column>
          <Column third>
            <Heading html="h4" level="h4">
              {block.sectionHeadingThree}
            </Heading>
            <Paragraph level="md">{block.sectionContentThree}</Paragraph>
          </Column>
        </Flex>
      </Container>
    </Section>
  )
}

export default Integrations

export const blockIntegrations = graphql`
  fragment BlockIntegrations on DatoCmsBlockIntegration {
    id
    model {
      apiKey
    }
    heading
    content
    image {
      alt
      title
      gatsbyImageData(
        width: 2000
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
    }
    sectionHeadingOne
    sectionContentOne
    sectionHeadingTwo
    sectionContentTwo
    sectionHeadingThree
    sectionContentThree
  }
`
