import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { Heading, Paragraph } from "@atoms/typography"
import { Section, Container, Flex, Column } from "@atoms/layout"

const FeaturesFourColumns = ({ block }) => {
  return (
    <Section xl xlTop>
      {block.title && (
        <Container sm paragraph containerSmall>
          <Heading html="h2" level="h2" center>
            {block.title}
          </Heading>
        </Container>
      )}
      <Container>
        <Flex>
          <Item quarter>
            <GatsbyImage
              image={block.oneIcon.gatsbyImageData}
              alt={block.oneIcon.alt}
              title={block.oneIcon.title}
            />
            <Heading html="h3" level="h4" center>
              {block.oneTitle}
            </Heading>
            <Paragraph level="md" center>
              {block.oneContent}
            </Paragraph>
          </Item>
          <Item quarter>
            <GatsbyImage
              image={block.twoIcon.gatsbyImageData}
              alt={block.twoIcon.alt}
              title={block.twoIcon.title}
            />
            <Heading html="h3" level="h4" center>
              {block.twoTitle}
            </Heading>
            <Paragraph level="md" center>
              {block.twoContent}
            </Paragraph>
          </Item>
          <Item quarter>
            <GatsbyImage
              image={block.threeIcon.gatsbyImageData}
              alt={block.threeIcon.alt}
              title={block.threeIcon.title}
            />
            <Heading html="h3" level="h4" center>
              {block.threeTitle}
            </Heading>
            <Paragraph level="md" center>
              {block.threeContent}
            </Paragraph>
          </Item>
          <Item quarter>
            <GatsbyImage
              image={block.fourIcon.gatsbyImageData}
              alt={block.fourIcon.alt}
              title={block.fourIcon.title}
            />
            <Heading html="h3" level="h4" center>
              {block.fourTitle}
            </Heading>
            <Paragraph level="md" center>
              {block.fourContent}
            </Paragraph>
          </Item>
        </Flex>
      </Container>
    </Section>
  )
}

export default FeaturesFourColumns

export const blockFeaturesFourColumns = graphql`
  fragment BlockFeaturesFourColumn on DatoCmsBlockFeaturesFourColumn {
    id
    title
    oneIcon {
      alt
      title
      gatsbyImageData(
        width: 120
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
    }
    oneTitle
    oneContent
    twoIcon {
      alt
      title
      gatsbyImageData(
        width: 120
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
    }
    twoTitle
    twoContent
    threeIcon {
      alt
      title
      gatsbyImageData(
        width: 120
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
    }
    threeTitle
    threeContent
    fourIcon {
      alt
      title
      gatsbyImageData(
        width: 120
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
    }
    fourTitle
    fourContent
    model {
      apiKey
    }
  }
`

const Item = styled(Column)`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .gatsby-image-wrapper {
    margin-bottom: var(--spacing--xxs);
  }
`
