import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { Heading } from "@atoms/typography"
import { Section, Container, Flex, Column, Content } from "@atoms/layout"

const DeveloperTools = ({ block }) => {
  return (
    <Section lg lgTop light={block.background}>
      <Container>
        <Flex alignCenter rowReverse={block.layout}>
          <Column half>
            {block.image && (
              <GatsbyImage
                image={block.image.gatsbyImageData}
                alt={block.image.alt}
                title={block.image.title}
              />
            )}
          </Column>
          <Column half>
            <Content paragraph>
              <Heading html="h2" level="h2">
                {block.title}
              </Heading>
              <div
                dangerouslySetInnerHTML={{
                  __html: block.content,
                }}
              />
            </Content>
          </Column>
        </Flex>
      </Container>
    </Section>
  )
}

export default DeveloperTools

export const blockDeveloperTools = graphql`
  fragment BlockDeveloperTools on DatoCmsBlockDeveloperTool {
    id
    title
    layout
    content
    background
    model {
      apiKey
    }
    image {
      alt
      title
      gatsbyImageData(
        width: 800
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
    }
  }
`
