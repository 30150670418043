import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Heading } from "@atoms/typography"
import { Section, Container } from "@atoms/layout"
import { media } from "@styles/breakpoints"

const FeaturesSimple = ({ block }) => {
  return (
    <Section lg lgTop>
      <Container sm paragraph containerSmall>
        <Heading html="h2" level="h2" center>
          {block.title}
        </Heading>
      </Container>
      <Container>
          <Features dangerouslySetInnerHTML={{ __html: block.content }}/>
      </Container>
    </Section>
  )
}

export default FeaturesSimple

export const blockFeaturesSimple = graphql`
  fragment BlockFeaturesSimple on DatoCmsBlockFeaturesSimple {
    id
    title 
    content
    model {
      apiKey
    }
  }
`

const Features = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: var(--spacingReverseGutter);
    margin-right: var(--spacingReverseGutter);
    position: relative;

    li {
      flex: 0 0 100%;
      padding: 0 var(--spacing--sm);
      position: relative;
      width: 100%;

      ${media.sm`
        padding: 0 var(--spacingGutter) var(--spacingGutter);
        flex: 0 0 25%; width: 25%;
      `}

      &:before {
        background-color: var(--colorPrimary);
        border-radius: 50%;
        content: "";
        width: 0.35rem;
        height: 0.35rem;
        left: 1rem;
        position: absolute;
        top:0.65rem;

        ${media.sm`
          left: 0rem;
          top:0.65rem;
          width: 0.45rem;
          height: 0.45rem;
        `}
      }
    }
  }
`
