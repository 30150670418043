import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { Heading, Paragraph } from "@atoms/typography"
import { Section, Container, Flex, Column } from "@atoms/layout"
import { media } from "@styles/breakpoints"

const OpenForms = ({ block }) => {
  return (
    <Section xl xlTop>
      {block.title && (
        <Container sm paragraph containerSmall>
          <Heading html="h2" level="h2" center>
            {block.title}
          </Heading>
          <Paragraph level="md" center>
            {block.subheading}
          </Paragraph>
        </Container>
      )}
      <Container>
        <Flex>
          <Item third>
            <Icon>
              <GatsbyImage
                image={block.oneIcon.gatsbyImageData}
                alt={block.oneIcon.alt}
                title={block.oneIcon.title}
              />
            </Icon>
            <Content>
              <Heading html="h3" level="h4">
                {block.oneTitle}
              </Heading>
              <Paragraph level="md">{block.oneContent}</Paragraph>
            </Content>
          </Item>
          <Item third>
            <Icon>
              <GatsbyImage
                image={block.twoIcon.gatsbyImageData}
                alt={block.twoIcon.alt}
                title={block.twoIcon.title}
              />
            </Icon>
            <Content>
              <Heading html="h3" level="h4">
                {block.twoTitle}
              </Heading>
              <Paragraph level="md">{block.twoContent}</Paragraph>
            </Content>
          </Item>
          <Item third>
            <Icon>
              <GatsbyImage
                image={block.threeIcon.gatsbyImageData}
                alt={block.threeIcon.alt}
                title={block.threeIcon.title}
              />
            </Icon>
            <Content>
              <Heading html="h3" level="h4">
                {block.threeTitle}
              </Heading>
              <Paragraph level="md">{block.threeContent}</Paragraph>
            </Content>
          </Item>
        </Flex>
      </Container>
    </Section>
  )
}

export default OpenForms

export const blockOpenForms = graphql`
  fragment BlockOpenForm on DatoCmsBlockOpenForm {
    id
    title
    subheading
    content
    oneIcon {
      alt
      title
      gatsbyImageData(
        width: 120
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
    }
    oneTitle
    oneContent
    twoIcon {
      alt
      title
      gatsbyImageData(
        width: 120
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
    }
    twoTitle
    twoContent
    threeIcon {
      alt
      title
      gatsbyImageData(
        width: 120
        layout: CONSTRAINED
        placeholder: BLURRED
        forceBlurhash: false
        imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
      )
    }
    threeTitle
    threeContent
    model {
      apiKey
    }
  }
`

const Item = styled(Column)`
  display: flex;
  margin-bottom: 2rem;

  ${media.sm`
    margin-bottom: 0;
  `}
`

const Content = styled.div`
  flex: 1 1 100%;
  margin-left: 1.5rem;
`

const Icon = styled.div`
  margin: 0 auto var(--spacing--xxs);
  max-width: 112px;
  flex: 1 1 112px;
`
