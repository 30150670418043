import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import styled from "styled-components"

import { Section, Container, Flex } from "@atoms/layout"
import { Heading, Paragraph } from "@atoms/typography"
import { media } from "@styles/breakpoints"

const MatchYourBrand = ({ block }) => {
  const checkouts = useRef(null)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }
    ScrollTrigger.matchMedia({
      "(max-width: 480px)": function () {
        gsap.to(checkouts.current, {
          scrollTrigger: {
            trigger: checkouts.current,
            start: "top bottom",
            end: "bottom top",
            scrub: 0.5,
            toggleActions: "play none none none",
          },
          x: "-100%",
        })
      },
      "(min-width: 480px)": function () {
        gsap.to(checkouts.current, {
          scrollTrigger: {
            trigger: checkouts.current,
            start: "top bottom",
            end: "bottom top",
            scrub: 0.5,
            toggleActions: "play none none none",
          },
          x: "-27%",
          y: "-5%",
        })
      },
    })
  }, [])

  const widthMobile = 320
  const widthDesktop = 640
  const quality = 90

  return (
    <BrandSection lgTop full>
      <Container containerSmall lg>
        <Heading html="h2" level="h2" center>
          {block.title}
        </Heading>
        <Paragraph
          level="md"
          center
          dangerouslySetInnerHTML={{ __html: block.content }}
        />
      </Container>
      <CheckoutContainer containerFull>
        <Checkout ref={checkouts}>
          <CheckoutRow alignCenter>
            <Mobile>
              <StaticImage
                src="../../../assets/visuals/checkout/brand/valley.png"
                alt="Valley Center custom MoonClerk checkout design"
                width={widthMobile}
                quality={quality}
              />
            </Mobile>
            <Mobile>
              <StaticImage
                src="../../../assets/visuals/checkout/brand/first.png"
                alt="First & Studio custom MoonClerk checkout design"
                width={widthMobile}
                quality={quality}
              />
            </Mobile>
            <Desktop>
              <StaticImage
                src="../../../assets/visuals/checkout/brand/silianas.png"
                alt="Silianas custom MoonClerk checkout design"
                width={widthDesktop}
                quality={quality}
              />
            </Desktop>
            <Default>
              <DefaultLine
                width="86"
                height="123"
                fill="none"
                viewBox="0 0 86 123"
              >
                <circle
                  cx="76"
                  cy="10"
                  r="10"
                  fill="#7CD6CD"
                  opacity="0.25"
                ></circle>
                <path
                  fill="#0AB7A8"
                  fillRule="evenodd"
                  d="M77.08 14.883A5.002 5.002 0 0076 5a5 5 0 00-.92 9.915c.053 3.049.095 5.77-.015 8.313-.184 4.268-.795 7.868-2.466 11.338-2.057 4.273-5.844 7.655-11.698 10.265-5.87 2.617-13.762 4.43-23.918 5.576C16.733 52.694 0 68.587 0 89.135v1.433a34.92 34.92 0 0020.59 31.844l.82-1.824A32.92 32.92 0 012 90.568v-1.433c0-19.365 15.796-34.549 35.208-36.74 10.246-1.157 18.369-3 24.507-5.737 6.153-2.743 10.368-6.41 12.686-11.224 1.834-3.808 2.472-7.708 2.662-12.12.11-2.564.07-5.36.017-8.43z"
                  clipRule="evenodd"
                ></path>
              </DefaultLine>
              <DefaultTitle html="h6" level="sh" strong gray>
                Our default checkout design
              </DefaultTitle>
              <StaticImage
                src="../../../assets/visuals/checkout/brand/default.png"
                alt="The default MoonClerk checkout design"
                width={widthMobile}
                quality={quality}
              />
            </Default>
            <Desktop>
              <StaticImage
                src="../../../assets/visuals/checkout/brand/mailme.png"
                alt="Mailme custom MoonClerk checkout design"
                width={widthDesktop}
                quality={quality}
              />
            </Desktop>
            <Mobile>
              <StaticImage
                src="../../../assets/visuals/checkout/brand/google.png"
                alt="Google course custom MoonClerk checkout design"
                width={widthMobile}
                quality={quality}
              />
            </Mobile>
            <Mobile>
              <StaticImage
                src="../../../assets/visuals/checkout/brand/claire.png"
                alt="Claire custom MoonClerk checkout design"
                width={widthMobile}
                quality={quality}
              />
            </Mobile>
          </CheckoutRow>
          <CheckoutRow alignCenter>
            <Desktop>
              <StaticImage
                src="../../../assets/visuals/checkout/brand/activity.png"
                alt="Activity Center custom MoonClerk checkout design"
                width={widthDesktop}
                quality={quality}
              />
            </Desktop>
            <Mobile>
              <StaticImage
                src="../../../assets/visuals/checkout/brand/oxley.png"
                alt="Oxley Coffee custom MoonClerk checkout design"
                width={widthMobile}
                quality={quality}
              />
            </Mobile>
            <Desktop>
              <StaticImage
                src="../../../assets/visuals/checkout/brand/everysmile.png"
                alt="Every Smile custom MoonClerk checkout design"
                width={widthDesktop}
                quality={quality}
              />
            </Desktop>
            <Mobile>
              <StaticImage
                src="../../../assets/visuals/checkout/brand/lean.png"
                alt="Lean gymn custom MoonClerk checkout design"
                width={widthMobile}
                quality={quality}
              />
            </Mobile>
            <Desktop>
              <StaticImage
                src="../../../assets/visuals/checkout/brand/tynni.png"
                alt="Tynni custom MoonClerk checkout design"
                width={widthDesktop}
                quality={quality}
              />
            </Desktop>
          </CheckoutRow>
        </Checkout>
      </CheckoutContainer>
    </BrandSection>
  )
}

export default MatchYourBrand

export const blockMatchYourBrand = graphql`
  fragment BlockMatchYourBrand on DatoCmsBlockMatchYourBrand {
    id
    title
    content
    model {
      apiKey
    }
  }
`

const BrandSection = styled(Section)`
  overflow: hidden;
`

const CheckoutContainer = styled(Container)`
  max-height: 320px;

  ${media.xs`
    max-height: 380px;
  `}

  ${media.sm`
    max-height: 560px;
  `}

  ${media.md`
    max-height: 680px;
  `}
`

const Checkout = styled.div`
  height: auto;
  width: 100vw;
`

const CheckoutRow = styled(Flex)`
  flex-wrap: nowrap;
  max-width: auto;
  width: 200vw;

  ${media.xs`
    width: 125vw;
  `}

  ${media.sm`
    width: 150vw;
  `}

  ${media.md`
    width: 105vw;
  `}
`

const Device = styled.div`
  margin: 0.75rem;
  position: relative;
  transition: var(--transitionBase);

  ${media.sm`
    margin: 1.25rem;
  `}
`

const Desktop = styled(Device)`
  flex: 1 0 30%;
`

const Mobile = styled(Device)`
  flex: 1 0 10%;
`

const Default = styled(Mobile)`
  &:after {
    border: 2px solid var(--aqua--60);
    border-radius: 5vw;
    content: "";
    height: calc(100% + 1.5rem);
    left: -0.75rem;
    position: absolute;
    top: -0.75rem;
    width: calc(100% + 1.5rem);

    ${media.sm`
      border-radius: 2vw;
    `}
  }
`

const DefaultTitle = styled(Heading)`
  left: 4rem;
  position: absolute;
  top: -5rem;
  font-size: 0.75rem;

  ${media.sm`
    font-size: 1rem;
  `}
`

const DefaultLine = styled.svg`
  left: -2rem;
  position: absolute;
  top: -5rem;
`
